import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import TermsConditions from '../containers/Terms/Terms';

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="privacy policy" />
    <TermsConditions />
  </Layout>
);

export default PrivacyPolicy;
